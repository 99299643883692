var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"padding1",staticStyle:{"background":"#f4f5fa"}},[(_vm.info==true)?_c('v-card',{staticClass:"elevation-0 pb-6 px-7",staticStyle:{"background":"transparent"}},[_c('v-card-text',{staticClass:"py-3 px-6",staticStyle:{"background":"#1976d1","color":"white","border-radius":"5px"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"white","small":""}},[_vm._v("mdi-information-outline")]),_vm._v(" Genera opciones personalizadas para los formularios de unocrm que serán afectados donde el catálogo exista. "),_c('v-icon',{staticClass:"mr-2",staticStyle:{"position":"absolute!important","top":"7px","right":"30px"},attrs:{"color":"white","small":""},on:{"click":function($event){_vm.info=false}}},[_vm._v("mdi-close")])],1)],1):_vm._e(),_c('v-card',{staticClass:"elevation-0 pb-4 padding2",staticStyle:{"background":"transparent"}},[_c('v-card-title',{staticStyle:{"background":"white"}},[_vm._v("Estatus "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Define las opciones de estatus en los que pueden estar tus clientes en el CRM.")])]),_c('v-spacer'),(_vm.dialogStatus==true)?_c('v-text-field',{attrs:{"label":"Nombre del Estatus"}}):_vm._e(),_c('v-btn',{staticClass:"elevation-0",staticStyle:{"height":"30px","font-size":"13px","font-weight":"400","text-transform":"capitalize","color":"#1976d1","background-color":"rgb(25 118 209 / 10%)!important"},attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.dialogStatus=true}}},[_vm._v("Agregar Nuevo")])],1),_c('statuses',{staticClass:"px-6"}),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialogStatus),callback:function ($$v) {_vm.dialogStatus=$$v},expression:"dialogStatus"}},[_c('createStatus',{on:{"closeDialogStatus":_vm.closeDialogStatus}})],1)],1),_c('v-card',{staticClass:"elevation-0 py-4 padding2",staticStyle:{"background":"transparent"}},[_c('v-card-title',{staticStyle:{"background":"white"}},[_vm._v("Rango de Consumo "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Establece los rangos de consumo aproximados para asignarlos a tus clientes.")])]),_c('v-spacer'),_c('v-btn',{staticClass:"elevation-0",staticStyle:{"height":"30px","font-size":"13px","font-weight":"400","text-transform":"capitalize","color":"#1976d1","background-color":"rgb(25 118 209 / 10%)!important"},attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.dialogPhase=true}}},[_vm._v("Agregar Nuevo")])],1),_c('phases',{staticClass:"px-6"}),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialogPhase),callback:function ($$v) {_vm.dialogPhase=$$v},expression:"dialogPhase"}},[_c('createPhases',{on:{"closeDialogPhase":_vm.closeDialogPhase}})],1)],1),_c('v-card',{staticClass:"elevation-0 py-4 padding2",staticStyle:{"background":"transparent"}},[_c('v-card-title',{staticStyle:{"background":"white"}},[_vm._v("Procedencias "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Agrega todos los medios por los cuales un cliente puede conocer tu negocio.")])]),_c('v-spacer'),_c('v-btn',{staticClass:"elevation-0",staticStyle:{"height":"30px","font-size":"13px","font-weight":"400","text-transform":"capitalize","color":"#1976d1","background-color":"rgb(25 118 209 / 10%)!important"},attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.dialogOrigin=true}}},[_vm._v("Agregar Nueva")])],1),_c('origins',{staticClass:"px-6"}),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.dialogOrigin),callback:function ($$v) {_vm.dialogOrigin=$$v},expression:"dialogOrigin"}},[_c('createOrigins',{on:{"closeDialogOrigin":_vm.closeDialogOrigin}})],1)],1),_c('v-card',{staticClass:"elevation-0 py-4 padding2",staticStyle:{"background":"transparent"}},[_c('v-card-title',{staticStyle:{"background":"white"}},[_vm._v("Tipos de Actividad "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("En este catalogo podras colocar los diferentes tipos de actividades que realizan tus vendedores, tales como llamadas, visitas, mensajes, correos, etc.")])]),_c('v-spacer'),_c('v-btn',{staticClass:"elevation-0",staticStyle:{"height":"30px","font-size":"13px","font-weight":"400","text-transform":"capitalize","color":"#1976d1","background-color":"rgb(25 118 209 / 10%)!important"},attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.dialogType=true}}},[_vm._v("Agregar Nuevo")])],1),_c('types',{staticClass:"px-6"}),_c('v-dialog',{attrs:{"max-width":"350px"},model:{value:(_vm.dialogType),callback:function ($$v) {_vm.dialogType=$$v},expression:"dialogType"}},[_c('createTypes',{on:{"closeDialogType":_vm.closeDialogType}})],1)],1),_c('v-card',{staticClass:"elevation-0 py-4 padding2",staticStyle:{"background":"transparent"}},[_c('v-card-title',{staticStyle:{"background":"white"}},[_vm._v("Motivos de Rechazo "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Aquí podras agregar los motivos por los cuales se puede cancelar una venta o cotización (precios, tiempos, etc.).")])]),_c('v-spacer'),_c('v-btn',{staticClass:"elevation-0",staticStyle:{"height":"30px","font-size":"13px","font-weight":"400","text-transform":"capitalize","color":"#1976d1","background-color":"rgb(25 118 209 / 10%)!important"},attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.dialogRejection=true}}},[_vm._v("Agregar Nuevo")])],1),_c('rejections',{staticClass:"px-6"}),_c('v-dialog',{attrs:{"max-width":"350px"},model:{value:(_vm.dialogRejection),callback:function ($$v) {_vm.dialogRejection=$$v},expression:"dialogRejection"}},[_c('createRejections',{on:{"closeDialogRejection":_vm.closeDialogRejection}})],1)],1),_c('v-card',{staticClass:"elevation-0 py-4 padding2",staticStyle:{"background":"transparent"}},[_c('v-card-title',{staticStyle:{"background":"white"}},[_vm._v("Medios de Contacto "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Este catalogo sirve para definir por que medio de contacto tu cliente prefiere que lo localices.")])]),_c('v-spacer'),_c('v-btn',{staticClass:"elevation-0",staticStyle:{"height":"30px","font-size":"13px","font-weight":"400","text-transform":"capitalize","color":"#1976d1","background-color":"rgb(25 118 209 / 10%)!important"},attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.dialogMode=true}}},[_vm._v("Agregar Nuevo")])],1),_c('contactMode',{staticClass:"px-6"}),_c('v-dialog',{attrs:{"max-width":"350px"},model:{value:(_vm.dialogMode),callback:function ($$v) {_vm.dialogMode=$$v},expression:"dialogMode"}},[_c('createContactMode',{on:{"closeDialogMode":_vm.closeDialogMode}})],1)],1),_c('v-card',{staticClass:"elevation-0 py-4 padding2",staticStyle:{"background":"transparent"}},[_c('v-card-title',{staticStyle:{"background":"white"}},[_vm._v("Usos de CFDI "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Usos de CFDI que se utilizan para el giro de tu negocio.")])]),_c('v-spacer'),_c('v-btn',{staticClass:"elevation-0",staticStyle:{"height":"30px","font-size":"13px","font-weight":"400","text-transform":"capitalize","color":"#1976d1","background-color":"rgb(25 118 209 / 10%)!important"},attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.dialogCfdi=true}}},[_vm._v("Agregar Nuevo")])],1),_c('cfdi',{staticClass:"px-6"}),_c('v-dialog',{attrs:{"max-width":"350px"},model:{value:(_vm.dialogCfdi),callback:function ($$v) {_vm.dialogCfdi=$$v},expression:"dialogCfdi"}},[_c('createCfdi',{on:{"closeDialogCfdi":_vm.closeDialogCfdi}})],1)],1),_c('v-card',{staticClass:"elevation-0 py-4 padding2",staticStyle:{"background":"transparent"}},[_c('v-card-title',{staticStyle:{"background":"white"}},[_vm._v("Tipos de cliente "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Etiqueta y diferencía a tus clientes por grupos generando tipos de clientes.")])]),_c('v-spacer'),_c('v-btn',{staticClass:"elevation-0",staticStyle:{"height":"30px","font-size":"13px","font-weight":"400","text-transform":"capitalize","color":"#1976d1","background-color":"rgb(25 118 209 / 10%)!important"},attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.dialogClientType=true}}},[_vm._v("Agregar Nuevo")])],1),_c('type',{staticClass:"px-6"}),_c('v-dialog',{attrs:{"max-width":"350px"},model:{value:(_vm.dialogClientType),callback:function ($$v) {_vm.dialogClientType=$$v},expression:"dialogClientType"}},[_c('createType',{on:{"closeDialogClientType":_vm.closeDialogClientType}})],1)],1),_c('v-card',{staticClass:"elevation-0 py-4 padding2",staticStyle:{"background":"transparent"}},[_c('v-card-title',{staticStyle:{"background":"white"}},[_vm._v("Zonas "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Este catálogo almacena las diferentes zonas de tu ciudad, estado, municipio.")])]),_c('v-spacer'),_c('v-btn',{staticClass:"elevation-0",staticStyle:{"height":"30px","font-size":"13px","font-weight":"400","text-transform":"capitalize","color":"#1976d1","background-color":"rgb(25 118 209 / 10%)!important"},attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.dialogZone=true}}},[_vm._v("Agregar Nueva")])],1),_c('zone',{staticClass:"px-6"}),_c('v-dialog',{attrs:{"max-width":"350px"},model:{value:(_vm.dialogZone),callback:function ($$v) {_vm.dialogZone=$$v},expression:"dialogZone"}},[_c('createZone',{on:{"closeDialogZone":_vm.closeDialogZone}})],1)],1),_c('v-card',{staticClass:"elevation-0 py-4 padding2",staticStyle:{"background":"transparent"}},[_c('v-card-title',{staticStyle:{"background":"white"}},[_vm._v("Frecuencia de consumo "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Diferencía que clientes te compran de manera anual, semestral, semanal, etc.")])]),_c('v-spacer'),_c('v-btn',{staticClass:"elevation-0",staticStyle:{"height":"30px","font-size":"13px","font-weight":"400","text-transform":"capitalize","color":"#1976d1","background-color":"rgb(25 118 209 / 10%)!important"},attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.dialogFrequency=true}}},[_vm._v("Agregar Nueva")])],1),_c('frequency',{staticClass:"px-6"}),_c('v-dialog',{attrs:{"max-width":"350px"},model:{value:(_vm.dialogFrequency),callback:function ($$v) {_vm.dialogFrequency=$$v},expression:"dialogFrequency"}},[_c('createFrequency',{on:{"closeDialogFrequency":_vm.closeDialogFrequency}})],1)],1),_c('v-card',{staticClass:"elevation-0 py-4 padding2",staticStyle:{"background":"transparent"}},[_c('v-card-title',{staticStyle:{"background":"white"}},[_vm._v("Condiciones Especiales "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Genera un catálogo de condiciones que te solicitan tus clientes al ofrecer tu producto/servicio.")])]),_c('v-spacer'),_c('v-btn',{staticClass:"elevation-0",staticStyle:{"height":"30px","font-size":"13px","font-weight":"400","text-transform":"capitalize","color":"#1976d1","background-color":"rgb(25 118 209 / 10%)!important"},attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.dialogSpecialCondition=true}}},[_vm._v("Agregar Nueva")])],1),_c('specialCondition',{staticClass:"px-6"}),_c('v-dialog',{attrs:{"max-width":"350px"},model:{value:(_vm.dialogSpecialCondition),callback:function ($$v) {_vm.dialogSpecialCondition=$$v},expression:"dialogSpecialCondition"}},[_c('createSpecialCondition',{on:{"closeDialogSpecialCondition":_vm.closeDialogSpecialCondition}})],1)],1),_c('v-card',{staticClass:"elevation-0 py-4 padding2",staticStyle:{"background":"transparent"}},[_c('v-card-title',{staticStyle:{"background":"white"}},[_vm._v("Lista de Precios "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Define si algunos clientes tienen un precio diferente que otros.")])]),_c('v-spacer'),_c('v-btn',{staticClass:"elevation-0",staticStyle:{"height":"30px","font-size":"13px","font-weight":"400","text-transform":"capitalize","color":"#1976d1","background-color":"rgb(25 118 209 / 10%)!important"},attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.dialogPriceList=true}}},[_vm._v("Agregar Nueva")])],1),_c('priceList',{staticClass:"px-6"}),_c('v-dialog',{attrs:{"max-width":"350px"},model:{value:(_vm.dialogPriceList),callback:function ($$v) {_vm.dialogPriceList=$$v},expression:"dialogPriceList"}},[_c('createPriceList',{on:{"closeDialogPriceList":_vm.closeDialogPriceList}})],1)],1),_c('v-card',{staticClass:"elevation-0 py-4 padding2",staticStyle:{"background":"transparent"}},[_c('v-card-title',{staticStyle:{"background":"white"}},[_vm._v("Métodos de Pago "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Define que métodos de pago son admitidos por tu negocio.")])]),_c('v-spacer'),_c('v-btn',{staticClass:"elevation-0",staticStyle:{"height":"30px","font-size":"13px","font-weight":"400","text-transform":"capitalize","color":"#1976d1","background-color":"rgb(25 118 209 / 10%)!important"},attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.dialogPaymentMethod=true}}},[_vm._v("Agregar Nuevo")])],1),_c('paymentMethod',{staticClass:"px-6"}),_c('v-dialog',{attrs:{"max-width":"350px"},model:{value:(_vm.dialogPaymentMethod),callback:function ($$v) {_vm.dialogPaymentMethod=$$v},expression:"dialogPaymentMethod"}},[_c('createPaymentMethod',{on:{"closeDialogPaymentMethod":_vm.closeDialogPaymentMethod}})],1)],1),_c('v-card',{staticClass:"elevation-0 py-4 padding2",staticStyle:{"background":"transparent"}},[_c('v-card-title',{staticStyle:{"background":"white"}},[_vm._v("Vehiculos "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("¿Manejas entregas? este catalogo te ayudara a registrar los vehículos de tu negocio y asignarlos en los envíos.")])]),_c('v-spacer'),_c('v-btn',{staticClass:"elevation-0",staticStyle:{"height":"30px","font-size":"13px","font-weight":"400","text-transform":"capitalize","color":"#1976d1","background-color":"rgb(25 118 209 / 10%)!important"},attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.dialogVehicle=true}}},[_vm._v("Agregar Nuevo")])],1),_c('vehicle',{staticClass:"px-6"}),_c('v-dialog',{attrs:{"max-width":"700px"},model:{value:(_vm.dialogVehicle),callback:function ($$v) {_vm.dialogVehicle=$$v},expression:"dialogVehicle"}},[_c('createVehicle',{on:{"closeDialogVehicle":_vm.closeDialogVehicle}})],1)],1),_c('v-card',{staticClass:"elevation-0 py-4 padding2",staticStyle:{"background":"transparent"}},[_c('v-card-title',{staticStyle:{"background":"white"}},[_vm._v("Categorías de Productos "),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-4",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-information-outline")])]}}])},[_c('span',[_vm._v("Agrupa tus productos por categorías para llevar un mejor control de los mismos.")])]),_c('v-spacer'),_c('v-btn',{staticClass:"elevation-0",staticStyle:{"height":"30px","font-size":"13px","font-weight":"400","text-transform":"capitalize","color":"#1976d1","background-color":"rgb(25 118 209 / 10%)!important"},attrs:{"color":"primary","dark":""},on:{"click":function($event){_vm.dialogCategory=true}}},[_vm._v("Agregar Nuevo")])],1),_c('category',{staticClass:"px-6"}),_c('v-dialog',{attrs:{"max-width":"350px"},model:{value:(_vm.dialogCategory),callback:function ($$v) {_vm.dialogCategory=$$v},expression:"dialogCategory"}},[_c('createCategory',{on:{"closeDialogCategory":_vm.closeDialogCategory}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }